<template>
  <div>
    <navigation />
    <section class="section is-medium" id="agb">
      <div class="container">
        <div class="content">
          <h1 class="title is-2 is-size-4-mobile">
            {{ $t("views.pricing.headline") }}
          </h1>
          <hr class="is-placeholder is-hidden-mobile" />

          <div class="notification is-info is-light">
            <p>
              {{ $t("views.pricing.description") }}
            </p>
            <p>
              <a :href="getPricingFile" target="_blank">
                <button class="button is-info">
                  {{ $t("views.pricing.cta") }}
                </button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";

import { store } from "../store";

export default {
  name: "agb",
  components: {
    navigation,
    footerNavigation,
  },
  computed: {
    getPricingFile() {
      switch (store.selectedPartner?.AppCountry?.country_code) {
        case "BE":
          return "/files/RepGuide-Pricing-BE-10-2022.pdf";
        case "CH":
          return "/files/RepGuide-Preisliste-CH-Juni-2022.pdf";
        case "DK":
          return "/files/RepGuide-Pricing-DK-06-2022-EN.pdf";
        case "EE":
          return "/files/RepGuide-Pricing-BALTICS-02-2023-EN.pdf";
        case "ES":
          return "/files/RepGuide-Pricing-ES-PT-09-2023-ES-PT.pdf";
        case "FI":
          return "/files/RepGuide-Pricing-FI-06-2022-EN.pdf";
        case "FR":
          return "/files/RepGuide-Pricing-FR-10-2022.pdf";
        case "GB":
          return "/files/RepGuide-Pricing-GB-12-2022.pdf";
        case "IT":
          return "/files/RepGuide-Pricing-IT-09-2023.pdf";
        case "LT":
          return "/files/RepGuide-Pricing-BALTICS-02-2023-EN.pdf";
        case "LV":
          return "/files/RepGuide-Pricing-BALTICS-02-2023-EN.pdf";
        case "NL":
          return "/files/RepGuide-Pricing-NL-07-2022.pdf";
        case "NO":
          return "/files/RepGuide-Pricing-NO-06-2022-EN.pdf";
        case "PT":
          return "/files/RepGuide-Pricing-ES-PT-09-2023-ES-PT.pdf";
        case "SE":
          return "/files/RepGuide-Pricing-SE-06-2022-EN.pdf";
        case "CS":
          return "/files/RepGuide-Pricing-CS-SK-01-2023-CZ.pdf";
        case "SK":
          return "/files/RepGuide-Pricing-CS-SK-01-2023-CZ.pdf";
        case "PL":
          return "/files/RepGuide-Pricing-PL-02-2023-PL.pdf";
        case "HR":
          return "/files/RepGuide-Pricing-HR-SI-03-2023-EN.pdf";
        case "SI":
          return "/files/RepGuide-Pricing-HR-SI-03-2023-EN.pdf";
        default:
          return "/files/RepGuide-Preisliste-DE-AT-Juni-2022.pdf";
      }
    },
  },
};
</script>
